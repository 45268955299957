export default {
  "dashboard admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trung tâm điều khiển"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trung tâm điều khiển"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên hệ"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trang chủ"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xin chà0 i18n !!"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thêm"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cập nhật"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xem"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chỉnh sửa"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xóa"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xác nhận"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hủy bỏ"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đọc thêm"])},
  "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bình luận"])},
  "not found data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy dữ liệu!"])},
  "create-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo mới thành công!"])},
  "edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa thành công!"])},
  "delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa thành công!"])},
  "create-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo mới thất bại!"])},
  "edit-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa thất bại!"])},
  "delete-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xóa thất bại!"])},
  "alert-delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bạn có chắc muốn xóa ", _interpolate(_named("attr")), " này không?"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiêu đề"])},
  "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ảnh"])},
  "describe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mô tả"])},
  "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["theo dõi"])},
  "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["công khai"])},
  "not-published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["không công khai"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tóm tắt"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nội dung"])},
  "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tác giả"])},
  "parent tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thẻ cha"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["danh sách thẻ"])},
  "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thẻ"])},
  "tag management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý thẻ"])},
  "create tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm mới thẻ"])},
  "edit tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa thẻ"])},
  "blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh sách bài viết"])},
  "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bài viết"])},
  "blog management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý bài viết"])},
  "create blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm mới bài viết"])},
  "edit blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉnh sửa bài viết"])}
}