export default {
  "dashboard admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard Admin"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["update"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["view"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirm"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
  "more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["learn more"])},
  "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
  "not found data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found data!"])},
  "create-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful new creation!"])},
  "edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update successful!"])},
  "delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete successful!"])},
  "create-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New creation failed!"])},
  "edit-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit failed!"])},
  "delete-fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete failed!"])},
  "alert-delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Are you sure you want to delete this ", _interpolate(_named("attr")), "?"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["title"])},
  "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
  "describe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["describe"])},
  "follow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["follow"])},
  "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["published"])},
  "not-published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not published"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["summary"])},
  "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["content"])},
  "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["author"])},
  "parent tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["parent tag"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tags"])},
  "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tag"])},
  "tag management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag management"])},
  "create tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create tag"])},
  "edit tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit tag"])},
  "blogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blogs"])},
  "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blog"])},
  "blog management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog management"])},
  "create blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create blog"])},
  "edit blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit blog"])}
}